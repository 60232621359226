import React, { useState, useEffect, useRef } from "react"

function App() {
  const STARTING_TIME = 5
  const inputRef = useRef(null)

  const [text, setText] = useState("")
  const [timeRemaining, setTimeRemaining] = useState(STARTING_TIME)
  const [isTimeRunning, setIsTimeRunning] = useState(false)
  const [wordCount, setWordCount] = useState(0)

  useEffect(() => {
    inputRef.current.focus()
  }, [isTimeRunning])

  useEffect(() => {
    if (isTimeRunning && timeRemaining > 0) {
      setTimeout(() => {
        setTimeRemaining(time => time - 1)
      }, 1000)
    } else if (timeRemaining === 0) {
      endGame()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRemaining, isTimeRunning])

  function handleChange(e) {
    const { value } = e.target
    setText(value)
  }

  function calculateWordCount(text) {
    const wordsArr = text.trim().split(" ")
    return wordsArr.filter(word => word !== "").length
  }

  function startGame() {
    setIsTimeRunning(true)
    setTimeRemaining(STARTING_TIME)
    setText("")
  }

  function endGame() {
    setIsTimeRunning(false)
    setWordCount(calculateWordCount(text))
  }

  return (
    <div>
      <h1>How fast do you type?</h1>
      <textarea
        ref={inputRef}
        onChange={handleChange}
        value={text}
        disabled={!isTimeRunning}
      />
      <h1>Time remaining: {timeRemaining}</h1>
      <button
        onClick={startGame}
        disabled={isTimeRunning}
      >
        Start
      </button>
      <h4>Word count: {wordCount}</h4>
    </div>
  )
}

export default App
